import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		view: {}
	}),
	mutations: {
		setTerm( state, value ){
			state.term = value
		}
	},
	actions: {
		async getItem({ state, rootState }, itemID ) {
			const { data } = await axios
				.get(
					`${envs.API_URL}site/get_item/${itemID}`,
					{
						headers: getHeaders({ rootState })
					}
				)


			state.view = data.body
			return true
		}
	}
}
