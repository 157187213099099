<template>
  <div class="menu-search-box" v-if="$route.name != 'pages-search-result'">
    <input
      type="text"
      :placeholder="$t('header.search')"
      v-model="search"
      @keydown="keyWatcher"
      class="menu-search-box__input"
    />
    <button @click.prevent="doSearch" type="button" class="menu-search-box__button">
      Buscar
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    search: {
      get() {
        return this.$store.state.search.term
      },
      set(value) {
        this.$store.commit('search/setTerm', value)
      }
    }
  },
  methods: {
    keyWatcher(e) {
      if (e.key == 'Enter') {
        this.doSearch()
      }
    },
    doSearch() {
      this.$router.push({
        name: 'pages-search-result'
      })
    }
  }
}
</script>
