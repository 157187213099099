import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		email: '',
	}),
	mutations: {
		setEmail( state, value ){
			state.email = value
		}
	},
	actions: {
		async sign({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}site/news_sign`,
					{
						email: state.email
					}, {
						headers: getHeaders({ rootState })
					})
            return true
		}
	}
}
