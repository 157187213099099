import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		data: {},
	}),
	actions: {
		async send({ state, rootState }) {
			const formData = new FormData()
			var insertData = JSON.stringify({
				nome: state.data.name,
				email: state.data.email,
				message: state.data.message
			})

			formData.append( 'insertData', insertData )
			if( state.data.file ){
				formData.append( 'files', state.data.file )
			}

			const { data } = await axios
				.post(
					`${envs.API_URL}site/contact_send`,
					formData,
					{
						headers: getHeaders({ 
							rootState,
							'Content-Type': 'multipart/form-data'
						})
					}
				)
            return true
		}
	}
}
