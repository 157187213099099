import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import layouts from './plugins/layouts'
import AxiosInterceptors from './plugins/axiosInterceptors'

import i18n from './i18n'

Vue.use( layouts )
Vue.use( AxiosInterceptors )

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
