import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
		loading: false,
		downloading: null,
		currentFolderPath: [],
		downloads: {},
		search: '',
	}),
	actions: {
		async list({ state, rootState }, { columnIndex, id }) {
			state.loading = true
			state.list = state.list.slice(0, columnIndex + 1)
			if (id) {
				state.currentFolderPath = state.currentFolderPath.slice(0, columnIndex)
				state.currentFolderPath.push(id)
			}
			const { data } = await axios
				.get(`${envs.API_URL}site/folders/${id ? id : ''}`, {
					headers: getHeaders({ rootState })
				})
			if (data.body.list.length > 0) {
				state.list.push(data.body.list)
			}
			state.loading = false
		},
		async download({ state, rootState }, id) {
			state.downloading = id
			const { data } = await axios
				.get(`${envs.API_URL}site/files/download/${id}`, {
					headers: getHeaders({ rootState })
				})
			state.downloading = null
			if (data.status) {
				state.downloads[id] = data.body
			}
		},
		async search({ state, rootState }, search) {
			state.search = search
			state.loading = true
			state.list = []
			state.currentFolderPath = []
			const { data } = await axios
				.get(`${envs.API_URL}site/folders-files/search/${search}`, {
					headers: getHeaders({ rootState })
				})
			if (data.body.list.length > 0) {
				state.list.push(data.body.list)
			}
			state.loading = false
		},
		resetSearch({ state }) {
			state.search = ''
			state.list = []
			state.currentFolderPath = []
		}
	}
}
