import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
  namespaced: true,
  state: () => ({
    term: '',
    list: {
      highlights: [],
      verticals: [],
      events: [],
      materials: [],
      calendars: [],
      institutionals: [],
      saletools: [],
      tickets: [],
      box: [],
    },
    searched: false,
    loading: false,
  }),
  mutations: {
    setTerm(state, value) {
      state.term = value
    }
  },
  actions: {
    async search({ state, rootState }) {
      state.loading = true
      state.list = {
        highlights: [],
        verticals: [],
        events: [],
        materials: [],
        calendars: [],
        institutionals: [],
        saletools: [],
        tickets: [],
        box: [],
      }

      const { data } = await axios
        .post(
          `${envs.API_URL}site/search`,
          {
            term: state.term
          },
          {
            headers: getHeaders({ rootState })
          }
        )

      const types = {
        1: 'highlights',
        2: 'verticals',
        3: 'events',
        5: 'materials',
        6: 'calendars',
        7: 'institutionals',
        8: 'saletools',
        9: 'tickets',
        100: 'box',
      }

      data.body.forEach(item => {
        const typeItem = types[+item.type]
        if (state.list[typeItem]) {
          state.list[typeItem].push(item)
        }
      })

      state.searched = true
      state.loading = false
      return true
    }
  }
}
