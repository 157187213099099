<template>
  <div id="layout-default">
    <div class="elem-language">
      <div class="container">
        <div
          class="btn-lang-selector"
          :class="{ active: currentLang == 'PT' }"
          @click="setLang('PT')"
        >
          <img class="img-flag" src="/pt.png" />
        </div>
        <div
          class="btn-lang-selector"
          :class="{ active: currentLang == 'ES' }"
          @click="setLang('ES')"
        >
          <img class="img-flag" src="/es.png" />
        </div>
        <div
          class="btn-lang-selector"
          :class="{ active: currentLang == 'EN' }"
          @click="setLang('EN')"
        >
          <img class="img-flag" src="/en.png" />
        </div>
        <div class="menu">
          <!-- <img src="menu_hamburguer.svg" /> -->
          <a @click="logout"><img src="/logout.svg" /></a>
        </div>
      </div>
    </div>
    <header class="elem-menu">
      <div class="container">
        <div class="s-left">
          <a href="/" @click.prevent="home">
            <img src="/fluke-logo.gif" />
          </a>
          <i>{{ $t('header.slogan') }}</i>
        </div>
        <div class="s-right">
          <div class="search">
            <menu-search-box />
          </div>
        </div>
      </div>
    </header>
    <slot />
    <footer class="elem-footer">
      <div class="container">
        <div class="copyright">Copyright ©1995-2021 Fluke Corporation</div>
        <div class="socials">
          <a href="https://www.instagram.com/fluke.brasil/" target="_blank"
            ><img src="/ig_icon.svg"
          /></a>
          <a href="https://pt.linkedin.com/company/fluke-do-brasil" target="_blank"
            ><img src="/linkedin_icon.svg"
          /></a>
          <a href="https://www.facebook.com/Fluke.Brasil/" target="_blank"
            ><img src="/fb_icon.svg"
          /></a>
          <a href="https://www.youtube.com/user/flukebrasil" target="_blank"
            ><img src="/youtube_icon.svg"
          /></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import MenuSearchBox from '../components/MenuSearchBox.vue'

export default {
  components: {
    MenuSearchBox
  },
  computed: {
    currentLang() {
      return this.$store.state.language.lang
    }
  },
  async created() {
    const language = await this.$store.dispatch('language/check')
    this.setLang(language)
    this.$i18n.locale = language.toLowerCase()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push({
        name: 'login'
      })
    },
    async setLang(lang) {
      this.$store.commit('language/setLang', lang)
      var language = lang.toLowerCase()
      this.$i18n.locale = language
    },
    home() {
      this.$router.push({
        name: 'pages-home'
      })
    }
  }
}
</script>

<style lang="scss">
.img-flag {
  display: inline;
  width: 50px;
  padding-right: 10px;
}
</style>
