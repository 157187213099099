<template>
  <div id="app">
		<component :is="layout">
			<router-view />
		</component>
  </div>
</template>

<style lang="scss">
@import 'assets/scss/app';
</style>

<script>
export default {
	computed: {
		layout() {
			return ( this.$route.meta.layout || "f-default" ) + "-layout"
		}
	}
}
</script>