export default [
	{
		path: '/',
		name: 'pages-home',
		component: function () {
			return import(/* webpackChunkName: "pages-home" */ '../views/pages/Home.vue')
		}
	},
	{
		path: '/search',
		name: 'pages-search-result',
		component: function () {
			return import(/* webpackChunkName: "pages-search-result" */ '../views/pages/SearchResult.vue')
		}
	},
	{
		path: '/view/:id',
		name: 'pages-view',
		component: function () {
			return import(/* webpackChunkName: "pages-view" */ '../views/pages/View.vue')
		}
	},
]