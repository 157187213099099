import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

import pages from './pages'
import login from './login'

Vue.use(VueRouter)

const routes = [
  ...pages,
  ...login
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async (to, from, next) => {
	if(
		![
			'login',
			'logout',
			'login-forgot',
			'login-reset',
			'login-reset-password',
			'login-set-password'
		].includes(to.name) 
	) {
		var isLogged = await Store.dispatch('auth/check_login')

		if( !isLogged ) {
			next({ name: 'login' })
		} else next()
	} else next()
})

export default router
