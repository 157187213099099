export default {
	namespaced: true,
	state: () => ({
		lang: 'PT'
	}),
	mutations: {
		setLang( state, value ){
			state.lang = value

			localStorage.setItem(
				'flukePortDistLang',
				value
			)
		}
	},
	actions: {
		check({state}){
			const lang = localStorage.getItem(
				'flukePortDistLang'
			)

			if( lang ) {
				return lang
			} else {
				return state.lang
			}
		}
	}
}
