import Vue from 'vue'
import Vuex from 'vuex'

import highlights from './highlights'
import verticals from './verticals'
import events from './events'
import materials from './materials'
import calendars from './calendars'
import institutionals from './institutionals'
import saletools from './saletools'
import tickets from './tickets'
import search from './search'
import language from './language'
import loading from './loading'
import view from './view'
import newsletter from './newsletter'
import contact from './contact'
import auth from './auth'
import box from './box'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    highlights,
    verticals,
    events,
    materials,
    calendars,
    institutionals,
    saletools,
    tickets,
    search,
    language,
    loading,
    view,
    newsletter,
    contact,
    auth,
    box
  }
})
