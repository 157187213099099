import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
	}),
	actions: {
		async list({ state, rootState }) {
			const { data } = await axios
				.get(`${envs.API_URL}site/materials`, {
					headers: getHeaders({ rootState })
				})
			state.list = data.body
		}
	}
}
